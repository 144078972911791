import { RatingUtilityEnvironment } from './environment.interface';

export const environment: RatingUtilityEnvironment = {
  production: true,
  requireAuth: true,
  azureClientId: '99c4a38b-6286-4285-8337-0df132486f4c',
  azureTenantId: 'b45b2e14-b22d-4196-b48c-b1b5506c874a',
  enableNgRxMiddleware: true,
  enableReduxDevTools: true,
  rateLookupUrl: 'https://roadrunner-rate-lookup-qa.herokuapp.com/',
  logRocketAppId: 'npobc9/roadrunner-ui',
  enableLicensing: true,
  enableFileImportExportMenuItem: true,
  enableFileImportTab: true,
};
